import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import { Store } from 'store/store';

const EntrepotCreate = () => {
	const { state: { user } } = useContext(Store)
	const { fetchApi } = useApi()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const create = useCallback(async (infos) => {
		setLoading(true)
		try {
			const entrepot = await fetchApi('entrepot', { body: { ...infos, owner: user.id } })
			toast.success('entrepot créé.')
			setLoading(false)
			navigate('/entrepot/' + entrepot.id)
		} catch (error) {
			setLoading(false)
			toast.error('Erreur durant la création de l\'entrepot: ' + error.message)
		}
	}, [user, fetchApi, navigate])
	const [entrepots, setEntrepots] = useState([])
	useEffect(() => {
		fetchApi('entrepots')
			.then(setEntrepots)
	}, [fetchApi])

	const entrepotParentBase = useMemo(() => entrepots.find(e => e.parent != null)?.parent || null, [entrepots])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'parent',
			label: 'Entrepôt parent',
			info: "Les dépôts seront décomptés de cet entrepot si il existe. Vous n'êtes pas obligé de le remplir si c'est lui-même un parent.",
			value: entrepotParentBase ? String(entrepotParentBase) : null,
			options: entrepots.map((entr) => ({
				key: String(entr.id),
				value: String(entr.id),
				text: entr.name
			})),
		},
		{
			type: 'text',
			name: 'name',
			label: 'Nom',
			required: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'address',
			label: 'Adresse',
			required: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'cp',
			label: 'Code Postal',
			required: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'city',
			label: 'Ville',
			required: true,
			silentRequired: true,
		},
	].filter(e => !!e), [entrepotParentBase, entrepots])


	return (
		<div className={classes.marginAuto}>
			<h2>Création de l'entrepot</h2>
			<FormComponent
				loading={loading}
				inputs={inputs}
				sendForm={create}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default EntrepotCreate;