import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FormComponent from 'components/Form/Form';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import toast from 'components/toast';

const RelationCreate = () => {
	const [searchParams] = useSearchParams()
	const { fetchApi } = useApi()
	const [products, setProducts] = useState([])
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const toProduct = useMemo(() => Number(searchParams.get('toProduct') || 0) || null, [searchParams])
	const fromProduct = useMemo(() => Number(searchParams.get('fromProduct') || 0) || null, [searchParams])
	const [indices, setIndices] = useState([])

	useEffect(() => {
		fetchApi('products')
			.then(setProducts)
	}, [fetchApi])


	useEffect(() => {
		if (toProduct != null) {
			fetchApi('indices?product=' + toProduct)
				.then(setIndices)
		}
	}, [toProduct, fetchApi])

	const onUpdate = useCallback((updatableValues) => {
		const initialSearch = searchParams.toString()
		const actualToProduct = searchParams.get('toProduct')
		if (actualToProduct !== updatableValues.toProduct) {
			if (!updatableValues.toProduct) {
				searchParams.delete('toProduct')
			} else {
				searchParams.set('toProduct', updatableValues.toProduct)
			}
		}
		const actualFromProduct = searchParams.get('fromProduct')
		if (actualFromProduct !== updatableValues.fromProduct) {
			if (!updatableValues.fromProduct) {
				searchParams.delete('fromProduct')
			} else {
				searchParams.set('fromProduct', updatableValues.fromProduct)
			}
		}
		if (searchParams.toString() !== initialSearch) {
			navigate('/relation/create?' + searchParams.toString(), { replace: true })
		}
	}, [searchParams, navigate])

	const create = useCallback(async (infos) => {
		setLoading(true)
		try {
			const relation = await fetchApi('relation', {
				method: 'POST',
				body: infos
			})
			toast.success('Relation créée.')
			setLoading(false)
			return navigate('/relation/' + relation.id)
		} catch (error) {
			setLoading(false)
			toast.error('Erreur durant la création de la relation: ' + error.message)
		}
	}, [fetchApi, navigate])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'fromProduct',
			label: 'Produit déclencheur',
			required: true,
			silentRequired: true,
			value: fromProduct ? String(fromProduct) : null,
			options: products.map((product) => ({
				key: String(product.id),
				value: String(product.id),
				text: product.name
			})),
		},
		{
			type: 'autocomplete',
			name: 'toProduct',
			label: 'Produit automatiquement ajouté',
			info: "Une fois le produit déclencheur ajouté, une nouvelle fenêtre s'ouvrira pour ajouter ce produit et sera pré-remplie.",
			required: true,
			silentRequired: true,
			value: toProduct ? String(toProduct) : null,
			options: products.map((product) => ({
				key: String(product.id),
				value: String(product.id),
				text: product.name
			})),
		},
		{
			type: 'autocomplete',
			name: 'indice',
			label: 'Indice',
			value: indices[0]?.id ? String(indices[0]?.id) : null,
			options: indices.map((indice) => ({
				key: String(indice.id),
				value: String(indice.id),
				text: indice.name + ' x' + indice.quantity
			})),
		},
		{
			type: 'number',
			name: 'quantity',
			label: 'Quantité',
		},
	].filter(e => !!e), [products, indices, fromProduct, toProduct])


	return (
		<div className={classes.marginAuto}>
			<h2>Créer une relation entre 2 produits</h2>
			<FormComponent
				onUpdate={onUpdate}
				key={JSON.stringify(inputs)}  // Utilisation de JSON.stringify pour générer une clé unique basée sur les inputs
				loading={loading}
				inputs={inputs}
				sendForm={create}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default RelationCreate;
