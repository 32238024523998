import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { actionStatuses, actionTypes } from './action.constant';
import FormComponent from 'components/Form/Form';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import toast from 'components/toast';
import { isEmpty, upperFirst } from 'lodash';
import { Store } from 'store/store';

const ActionCreate = () => {
	const [searchParams] = useSearchParams()
	const { state: { user: me } } = useContext(Store)
	const { fetchApi } = useApi()
	const [params] = useSearchParams()
	const type = useMemo(() => Number(params.get('type') || 0), [params])
	const product = useMemo(() => Number(params.get('product') || 0) || null, [params])
	const entrepot = useMemo(() => Number(params.get('entrepot') || 0) || null, [params])
	const owner = useMemo(() => Number(params.get('owner') || 0) || null, [params])
	const user = useMemo(() => Number(params.get('user') || 0) || null, [params])
	const defectueux = useMemo(() => !!Number(params.get('defectueux') || 0), [params])
	const indice = useMemo(() => Number(params.get('indice') || 0) || null, [params])
	const quantity = useMemo(() => Number(params.get('quantity') || 0) || null, [params])
	const typeReadeable = useMemo(() => defectueux ? 'Produit defectueux' : Object.keys(actionTypes)[type], [type, defectueux])
	const [products, setProducts] = useState([])
	const [entrepots, setEntrepots] = useState([])
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const [fromAction, setFromAction] = useState(null)

	const [indices, setIndices] = useState([])
	useEffect(() => {
		fetchApi('products')
			.then(setProducts)
		fetchApi('users')
			.then(setUsers)
		fetchApi('entrepots')
			.then(setEntrepots)
	}, [fetchApi])

	const onUpdate = useCallback((updatableValues, [name, value]) => {
		const initialSearch = searchParams.toString()
		const variables = ['product', 'entrepot', 'user', 'indice', 'quantity']
		for (const variable of variables) {
			const actual = searchParams.get(variable)
			if (actual !== updatableValues[variable]) {
				if (!updatableValues[variable]) {
					searchParams.delete(variable)
				} else {
					searchParams.set(variable, updatableValues[variable])
				}
			}
		}
		if (searchParams.toString() !== initialSearch) {
			searchParams.set('autoFocus', name)
			navigate('/action/create?' + searchParams.toString(), { replace: true })
		} else if (!!searchParams.get('autoFocus')) {
			searchParams.delete('autoFocus')
			navigate('/action/create?' + searchParams.toString(), { replace: true })
		}
	}, [searchParams, navigate])

	useEffect(() => {
		if (product != null) {
			fetchApi('indices?product=' + product)
				.then(setIndices)
		}
	}, [product, fetchApi])

	const isFromAction = useMemo(() => !isEmpty(fromAction), [fromAction])

	useEffect(() => {
		if (isEmpty(fromAction) && params.get('fromAction')) {
			fetchApi('action?id=' + params.get('fromAction'))
				.then(setFromAction)
		}
	}, [params, fetchApi, fromAction])

	const create = useCallback(async (infos) => {
		setLoading(true)
		try {
			const action = await fetchApi('action', {
				method: 'POST',
				body: {
					...infos,
					type,
					defectueux
				}
			})
			const relation = await fetchApi('relation?fromProduct=' + infos.product)
			toast.success('Action créée.')
			setLoading(false)
			if (!isEmpty(relation) && type === actionTypes.INSTALLATION && !defectueux) {
				const nextProduct = [
					`product=${relation.toProduct}`,
					`user=${user}`,
					`fromAction=${action.id}`,
					`type=${type}`
				]
				if (relation.indice) {
					nextProduct.push(`indice=${relation.indice}`)
				}
				if (relation.quantity) {
					nextProduct.push(`quantity=${relation.quantity}`)
				}
				return navigate(`/action/create?` + nextProduct.join('&'))
			}
			if (!!entrepot) {
				return navigate('/entrepot/' + entrepot)
			}
			if ([actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(type) && !!user) {
				return navigate('/admin/user/' + user)
			}
			return navigate('/action/' + action.id)
		} catch (error) {
			setLoading(false)
			toast.error('Erreur durant la création de l\'action: ' + error.message)
		}
	}, [fetchApi, type, defectueux, entrepot, user, navigate])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'product',
			label: 'Produit',
			required: true,
			silentRequired: true,
			value: product ? String(product) : null,
			options: products.map((product) => ({
				key: String(product.id),
				value: String(product.id),
				text: product.name
			})),
		},

		![actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(type) && {
			type: 'autocomplete',
			name: 'entrepot',
			label: 'Entrepot',
			required: true,
			silentRequired: true,
			value: entrepot ? String(entrepot) : null,
			options: entrepots.map((entrepot) => ({
				key: String(entrepot.id),
				value: String(entrepot.id),
				text: entrepot.name
			})),
		},
		type === actionTypes.TRANSFER && {
			type: 'autocomplete',
			name: 'owner',
			label: 'Depuis ce collaborateur',
			required: true,
			silentRequired: true,
			value: owner ? String(owner) : null,
			options: users.map((user) => ({
				key: String(user.id),
				value: String(user.id),
				text: upperFirst(user.firstname) + ' ' + upperFirst(user.lastname)
			})),
		},
		{
			type: 'autocomplete',
			name: 'user',
			label: type === actionTypes.TRANSFER ? 'Vers ce collaborateur' : 'Collaborateur',
			...([actionTypes.RETRAIT, actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(type) ? {
				required: true,
				silentRequired: true,
			} : {}),
			value: user ? String(user) : null,
			options: users.map((user) => ({
				key: String(user.id),
				value: String(user.id),
				text: upperFirst(user.firstname) + ' ' + upperFirst(user.lastname)
			})),
		},

		{
			type: 'autocomplete',
			name: 'indice',
			label: 'Indice',
			required: true,
			silentRequired: true,
			value: indice ? String(indice) : indices[0]?.id ? String(indices[0]?.id) : null,
			options: indices.map((indice) => ({
				key: String(indice.id),
				value: String(indice.id),
				text: indice.name + ' x' + indice.quantity
			})),
		},
		{
			type: 'number',
			name: 'quantity',
			label: 'Quantité',
			value: quantity ? String(quantity) : null,
			required: true,
			silentRequired: true,
			min: '0'
		},
		...(type === actionTypes.INSTALLATION && !defectueux ? [{
			type: 'text',
			name: 'customerId',
			label: 'ID Client',
			value: fromAction?.customerId ? String(fromAction?.customerId) : null,
		},

		{
			type: 'text',
			name: 'lastname',
			label: 'Nom Client',
			value: fromAction?.lastname ? String(fromAction?.lastname) : null,
		},

		{
			type: 'text',
			name: 'firstname',
			label: 'Prénom Client',
			value: fromAction?.firstname ? String(fromAction?.firstname) : null,
		},

		{
			type: 'text',
			name: 'address',
			label: 'Adresse Client',
			value: fromAction?.address ? String(fromAction?.address) : null,
		}] : []),
		{
			type: 'textArea',
			name: 'reference',
			label: 'Référence',
			value: fromAction?.reference ? String(fromAction?.reference) : null,
		},
		{
			type: 'textArea',
			name: 'description',
			label: 'Description',
			value: fromAction?.description ? String(fromAction?.description) : null,
		},
		{
			type: 'date',
			name: 'createdAt',
			label: 'Date',
			value: fromAction?.createdAt ? new Date(fromAction?.createdAt).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
			required: true,
			silentRequired: true,
		},
		[actionTypes.INSTALLATION, actionTypes.TRANSFER].includes(type) && {
			type: 'autocomplete',
			name: 'status',
			label: 'Statut',
			options: Object.keys(actionStatuses).map((status) => ({
				key: String(status),
				value: String(status),
				text: status
			})),
		},
	].filter(e => !!e), [product, products, type, entrepot, entrepots, user, users, indices, fromAction, indice, quantity, defectueux, owner])


	return (
		<div className={classes.marginAuto}>
			<h2>Créer un {typeReadeable}{isFromAction && <span style={{
				fontSize: 'small'
			}}> pré-remplie depuis le précédent</span>}</h2>

			<FormComponent
				onUpdate={onUpdate}
				key={JSON.stringify(inputs)}  // Utilisation de JSON.stringify pour générer une clé unique basée sur les inputs
				loading={loading}
				inputs={inputs}
				sendForm={create}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default ActionCreate;
