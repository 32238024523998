import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import classes from './MenuNavigationComponent.module.scss';

const MenuNavigationComponent = ({
	components = [{ title: '', component: '', value: '' }],
	queryName = 'nav'
}) => {
	const { pathname, search } = useLocation();
	const navigate = useNavigate();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);
	const index = useMemo(() => {
		const searchParams = new URLSearchParams(search);
		const queryValue = searchParams.get(queryName);
		if (queryValue) {
			const decodedQueryValue = decodeURIComponent(queryValue);
			const actualIndex = components.findIndex(({ title, value }) => (value || title) === decodedQueryValue)
			if (actualIndex !== -1) { return actualIndex }
			return 0;
		}
		return 0
	}, [search, components, queryName]);

	const initMenu = useCallback((components) => {
		return components.map(({ title, value }, idx) => ({ title, isActive: idx === index, value }));
	}, [index]);

	const [menu, setMenu] = useState(initMenu(components));

	useEffect(() => {
		setMenu(initMenu(components));
	}, [components]);

	const updateUrl = useCallback((title) => {
		searchParams.set(queryName, title)
		searchParams.delete('startDate')
		searchParams.delete('endDate')
		searchParams.delete('page')
		searchParams.delete('limit')
		searchParams.delete('search')
		navigate(`?${searchParams.toString()}`);
	}, [navigate, queryName, searchParams]);



	useEffect(() => {
		setMenu(menu => menu.map((item, idx) => ({
			...item,
			isActive: idx === index
		})));
	}, [index]);

	const activeComponent = useMemo(() => components[index]?.component, [components, index]);

	return (
		<div className={classes.root}>
			<div className={classes.menuContainer}>
				{menu.map(({ title, isActive, value }, idx) =>
					<div
						key={idx}
						onClick={() => updateUrl(value || title)}
						className={classNames(classes.centeredContainer, classes.itemContainer, isActive && classes.active)}>
						<div className={classes.item}>
							{title}
						</div>
					</div>
				)}
			</div>
			<div className={classes.componentContainer}>
				{activeComponent}
			</div>
		</div>
	);
};

export default MenuNavigationComponent;
