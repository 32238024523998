import React from 'react';
import FiltersTable from './FiltersTable';
import classes from '../../styles/classes.module.scss'
import SearchTable from './SearchTable';
import { useTableHeader } from './useTableHeader';
import classNames from 'classnames';
// import FilterColumns from './FilterColumns';
import Counter from './Counter';
import SearchOldTable from './SearchOldTable';

const HeaderOldTable = ({
    rows = [],
    setRows = () => { },
    filters: filtersFromParams = null,
    setColumns = () => { },
    columnsFromParams = [],
    columns = [],
    counter = false
}) => {
    const { updateFilters } = useTableHeader(rows, setRows)

    return (
        <div className={classNames(classes.flex, classes.flexWrap)}>
            <SearchOldTable
                updateFilters={value => updateFilters('search', value)}
            />
            {/* <FilterColumns
                setColumns={setColumns}
                columnsFromParams={columnsFromParams}
                columns={columns}
            /> */}

            {filtersFromParams && <FiltersTable
                filters={filtersFromParams}
                setRows={setRows}
                updateFilters={value => updateFilters('filter', value)}

            />}
            {counter && <Counter /> }
        </div>
    );
};

export default HeaderOldTable;