import MenuNavigationComponent from 'components/MenuNavigationComponent';
import React, { useMemo } from 'react';
import GeneralTableView from './GeneralTableView';
import { actionTypes } from './action.constant';
import classes from 'styles/classes.module.scss'

const Actions = () => {
	const components = useMemo(() => [
		{
			title: `Actions`,
			component:
				<GeneralTableView navigable fetchType={"actions"} type={"action"} initialSearchParams={{}} />
		},
		{
			title: `Dépots en entrepôt`,
			component:
				<GeneralTableView navigable fetchType={"actions"} type={"action"} initialSearchParams={{ type: actionTypes.DEPOT }} />
		},
		{
			title: `Installations`,
			component:
				<GeneralTableView navigable fetchType={"actions"} type={"action"} initialSearchParams={{ type: actionTypes.INSTALLATION }} />
		},
		{
			title: `Tous les transferts`,
			component:
				<GeneralTableView navigable fetchType={"actions"} type={"action"} initialSearchParams={{ type: actionTypes.TRANSFER }} />
		},
		{
			title: `Tous les retraits`,
			component:
				<GeneralTableView navigable fetchType={"actions"} type={"action"} initialSearchParams={{ type: actionTypes.RETRAIT }} />
		},
		{
			title: `Defectueux`,
			component:
				<GeneralTableView navigable fetchType={"actions"} type={"action"} initialSearchParams={{ type: actionTypes.INSTALLATION, defectueux: true }} />
		},
	], [])
	return (
		<div className={classes.marginAuto}>

			<MenuNavigationComponent
				queryName='view'
				components={components}
			/>
		</div>
	);
};

export default Actions;