import { Autocomplete, TextField, FormHelperText } from '@mui/material';
import { Form } from 'semantic-ui-react';
import Label from './Label';
import InfoInput from './InfoInput';
import classes from './Form.module.scss';
import classNames from 'classnames';
import { useMemo } from 'react';

const FormSelectAutocomplete = ({
  id,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  maxLength = null,
  label,
  legend = null,
  required,
  error,
  silentRequired = false,
  initialValue,
  handleEffect,
  options: optionsFromParams = [],
  requiredLang,
  info,
  formError = false
}) => {
  // Nous adaptons les options pour qu'elles utilisent la structure requise par Material-UI Autocomplete
  const options = useMemo(() => optionsFromParams.map(option => ({
    label: option.text,  // Autocomplete utilise 'label' pour l'affichage
    value: option.value
  })), [optionsFromParams]);

  const onValueChange = (event, newValue) => {
    handleEffect({
      target: { name: name, value: newValue ? newValue.value : '' }
    });
  };

  const selectedOption = useMemo(() => options.find(option => option.value === value) || null, [value, options]);

  const isError = useMemo(() => formError && error && required, [error, formError, required]);

  return (
    <Form.Field
      required={required}
      className={classNames(classes.field, classes.flex)}
    >
      {label &&
        <label className={classes.conformLabel} htmlFor={name + (initialValue ? '-' + initialValue : '')}>
          {label}
        </label>
      }
      <Autocomplete
        id={name + (initialValue ? '-' + initialValue : '')}
        name={name}
        value={selectedOption}
        onChange={onValueChange}
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder || ''}
            className={classNames(classes.myInput, isError && classes.error)}
            error={isError}
            helperText={isError ? requiredLang : ''}
            fullWidth
          />
        )}
      />
      {legend && <p className={classes.legendInput}>{legend}</p>}
      {info && <InfoInput info={info} />}
      {required && error && !silentRequired &&
        <Label
          style={{ display: 'inline-table' }}
          display={requiredLang}
          pointing={'left'}
          color={'red'}
        />
      }
    </Form.Field>
  );
};

export default FormSelectAutocomplete;
