import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GeneralTableView from './GeneralTableView';
import { useApi } from 'hooks/useApi';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import classes from 'styles/classes.module.scss'
// import MyStandartAutocomplete from 'components/MyStandartAutocomplete';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { useSearchParams } from 'react-router-dom';

const Entrepots = () => {
	const [searchParams] = useSearchParams()
	const { state: { products: productsFromContext } } = useStoreUtils()
	// const navigate = useNavigate()
	const { fetchApi } = useApi();
	// const [searchParams] = useSearchParams()
	// const [products, setProducts] = useState([]);
	// const productSelected = useMemo(() => searchParams.get('productSelected') || null, [searchParams])

	// useEffect(() => {
	// 	fetchApi('products')
	// 		.then(setProducts)
	// }, [fetchApi])

	const addProductFunc = useCallback((entrepot) => {
		const beforeDate = searchParams.get('beforeDate');
		let date = '';
		if (beforeDate) { date = `?beforeDate=${beforeDate}` }
		return fetchApi(`products/entrepot/${entrepot?.id}${date}`)
			.then(([data, count]) => {
				return {
					productsQuantity: (productsFromContext || []).map(p => ({
						...p,
						totalQuantity: data.find(({ id }) => id === p.id)?.totalQuantity || 0
					})),
					...entrepot,
				}
			})
	}
		, [fetchApi, productsFromContext, searchParams]);

	// const updateProductSelected = useCallback((item) => {
	// 	const { key: value } = item || {}
	// 	if (value) {
	// 		searchParams.set('productSelected', value)
	// 	} else {
	// 		searchParams.delete('productSelected')
	// 	}
	// 	navigate('/entrepots?' + searchParams.toString())
	// }, [searchParams, navigate])
	const orderColumns = useMemo(() => [
		'Nom',
		'Adresse',
		...(productsFromContext || []).map(({ name }) => `Quantité de ${name}`)
	], [productsFromContext])
	const total = useMemo(() => (productsFromContext || []).map(({ name }) => `Quantité de ${name}`), [productsFromContext])

	return (
		<div style={{ maxWidth: "100%" }} className={classNames(classes.marginAuto, classes.container)}>
			{/* <MyStandartAutocomplete
				id="productSelected"
				name="productSelected"
				placeholder="Produit"
				label="Produit"
				onUpdate={updateProductSelected}
				options={products.map((product) => ({
					key: String(product.id),
					index: String(product.id),
					id: String(product.id),
					label: product.name
				}))}
				defaultValue={productSelected} /> */}
			<GeneralTableView
				orderColumns={orderColumns} //les colonnes à afficher dans l'ordre que je veux //avec le nom de la colonne + count produit de tous les produits
				total={total}
				type={"entrepot"}
				navigable
				afterFetchAdding={addProductFunc}
				searchDates={false}
				beforeDate={true}
			/>

		</div>
	);
};

export default Entrepots;