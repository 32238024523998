import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { getOnlyUpdatedValues } from 'helpers';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { useApi } from 'hooks/useApi';
import { actionTypes } from './action.constant';
import MenuNavigationComponent from 'components/MenuNavigationComponent';
import GeneralTableView from './GeneralTableView';
import ProductsParentTotalTable from './ProductsParentTotalTable';

const Entrepot = () => {
	const { fetchApi } = useApi()
	const id = Number(useParams().entrepotId)
	const [entrepot, setEntrepot] = useState({})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		fetchApi('entrepot?id=' + id)
			.then(setEntrepot)

	}, [fetchApi, id])

	const [entrepots, setEntrepots] = useState([])
	useEffect(() => {
		fetchApi('entrepots')
			.then(setEntrepots)
	}, [fetchApi])
	const [entrepotChildren, setEntrepotChildren] = useState([])
	useEffect(() => {
		fetchApi('entrepot/children/' + id)
			.then(setEntrepotChildren)
	}, [fetchApi, id])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'parent',
			label: 'Entrepôt parent',
			info: "Les dépôts seront décomptés de cet entrepot si il existe. Vous n'êtes pas obligé de le remplir si c'est lui-même un parent.",
			value: entrepot.parent ? String(entrepot.parent) : null,
			options: entrepots.map((entr) => ({
				key: String(entr.id),
				value: String(entr.id),
				text: entr.name
			})),
		},
		{
			type: 'text',
			name: 'name',
			label: 'Nom',
			value: entrepot.name ? String(entrepot.name) : null,
			required: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'address',
			label: 'Adresse',
			value: entrepot.address ? String(entrepot.address) : null,
			required: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'cp',
			label: 'Code Postal',
			value: entrepot.cp ? String(entrepot.cp) : null,
			required: true,
			silentRequired: true,
		},
		{
			type: 'text',
			name: 'city',
			label: 'Ville',
			value: entrepot.city ? String(entrepot.city) : null,
			required: true,
			silentRequired: true,
		},
	], [entrepot, entrepots])

	const update = useCallback(async (values) => {
		setLoading(true)
		try {
			const updatedValues = getOnlyUpdatedValues({
				...entrepot,
			}, values, true)
			if (isEmpty(updatedValues)) {
				toast.error('Aucune info n\'a changé.')
				return
			}
			await fetchApi('entrepot?id=' + id, {
				method: 'PUT',
				body: { ...updatedValues },
			})
			setEntrepot(entrepot => ({ ...entrepot, ...updatedValues }))
			toast.success('Infos actualisées')
		} catch (error) {
			toast.error('Erreur durant l\'actualisation des infos :' + error.message)
			console.log('error', error)
			return
		} finally {
			setLoading(false)
		}
	}, [fetchApi, id, entrepot])



	const isParent = useMemo(() => !entrepot.parent, [entrepot])
	const entrepotChildrenIds = useMemo(() => isEmpty(entrepotChildren) ? null : entrepotChildren.map(e => e.id).join(','), [entrepotChildren])
	const components = useMemo(() => {
		const orderColumns = [
			'Date',
			"Type d'action",
			'Produit',
			'Quantité réelle',
			'Utilisateur',
			'Statut'
		]
		return [
			{
				title: `Produits`,
				component:
					<ProductsParentTotalTable parent={{ entrepot: id }} /> //décompter ce qui est chez les enfants
			},
			{
				title: `Actions`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ entrepot: id }} /> //ajouter ici les dépôts vers les enfants
			},
			{
				title: `Dépots`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ entrepot: id, type: actionTypes.DEPOT }} />
			},
			...(isParent && entrepotChildrenIds ? [{
				title: `Dépots chez entrepots enfants`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ entrepotInOp: entrepotChildrenIds, type: actionTypes.DEPOT }} />
			},
			{
				title: `Entrepots enfants`,
				component:
					<GeneralTableView navigable fetchType={"entrepots"} type={"entrepot"} initialSearchParams={{ parent: id }} />
			}] : []),
			{
				title: `Tous les retraits`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ entrepot: id, type: actionTypes.RETRAIT }} />
			}
		].filter(e => !!e)
	}, [id, isParent, entrepotChildrenIds])


	return (
		<>
			<div className={classNames(
				classes.marginAuto,
				// classes.flex,
				// classes.flexWrap
			)}>
				<Button link={`/action/create?type=${actionTypes.DEPOT}&entrepot=` + id}>Créer un dépôt</Button>
				<Button link={`/action/create?type=${actionTypes.RETRAIT}&entrepot=` + id}>Créer un retrait</Button>
				<div className={classNames(
					classes.marginAuto,
					classes.flex,
					classes.flexWrap
				)}>
					<div>
						<h3>Informations de l'entrepot</h3>
						<FormComponent
							loading={loading}
							inputs={inputs}
							sendForm={update}
							centered={true}
							responsive={false}
							segment={false}
							messages={{ submit: 'Actualiser les infos' }}
						/>
					</div>
				</div>
				<MenuNavigationComponent
					queryName='view'
					components={components}
				/>
			</div>

		</>
	);
};

export default Entrepot;