import { getHeaders } from "helpers"
import { useCallback, useContext } from "react"
import { Store } from "store/store"
import { useDisconnect } from "./useDisconnect"
import { saveToken } from "utils/Auth"
import { jwtDecode } from "jwt-decode"

export const useApi = () => {
	const { logout } = useDisconnect()
	const { dispatch } = useContext(Store)

	const fetchApi = useCallback((route, params = null, adminRoute = false, queryParams = '') => {
		const apiUrl = `${process.env.REACT_APP_API_URL}/${route}`;
		// console.log('apiUrl', apiUrl)
		const fetchUrl = !!queryParams ? `${apiUrl}?${queryParams}` : apiUrl;
		return fetch(
			fetchUrl,
			params ? {
				method: 'POST',
				headers: getHeaders(params),
				...params,
				body: params.multiform ? params.body : JSON.stringify(params.body || {}),
			} : { headers: getHeaders({}) })
			.then(async res => {
				//ici si il nous passe un nouveau token on l'enregistre dans le local storage
				if (!res.ok) { throw res }
				return params?.responseType === 'blob' ? res.blob() : res.json()
			}).catch(async err => {
				let { message } = err
				if (err.json) {
					const jsonError = await err.json()
					if (jsonError.message) {
						message = jsonError.message
					}
					if (message === 'Disconnect') {
						return logout()
					}
					throw jsonError
				}
				throw err
			})
	}, [logout])

	const getStatuses = useCallback(() => fetchApi('statuses'), [fetchApi])

	const createUser = useCallback((body) => fetchApi('user', { body }, true), [fetchApi])
	const getUser = useCallback((userId) => fetchApi('user/' + userId, null, true), [fetchApi])
	const updateUser = useCallback((userId, body) => fetchApi('user/' + userId, { body, method: 'PATCH' }, true), [fetchApi])
	const regenerateToken = useCallback(() =>
		fetchApi('regenerateToken', null, true)
			.then(token => {
				saveToken(token)
				const user = jwtDecode(token)
				dispatch({
					type: 'USER/UPDATE',
					payload: user
				})
			})
			.catch(logout)
		, [dispatch, fetchApi, logout])
	const updateMe = useCallback((body) => fetchApi('user', { body, method: 'PATCH' }, true)
		.then(regenerateToken), [fetchApi, regenerateToken])

	const getUsers = useCallback((userId) => fetchApi('syntheses/user/' + userId, null, true), [fetchApi])
	const getAllSyntheses = useCallback(() => fetchApi('syntheses', null, true), [fetchApi])
	const getAllUsers = useCallback(() => fetchApi('users', null, true), [fetchApi])
	const getNewReference = useCallback(() => fetchApi('syntheses/reference', null, true), [fetchApi])
	const chargeProducts = useCallback(() => fetchApi('products', null, true)
	.then(products => {
		dispatch({
			type: 'UPDATE_PRODUCTS',
			payload: products
		})
		return products
	}), [fetchApi, dispatch])

	return {
		fetchApi,
		getStatuses,
		createUser,
		getUser,
		updateUser,
		updateMe,
		regenerateToken,
		getUsers,
		getAllSyntheses,
		getAllUsers,
		getNewReference,
		chargeProducts
	}

}