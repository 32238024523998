import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker } from "antd";
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './PeriodSelection.module.scss';
import classNames from 'classnames';
import moment from 'moment';
const { RangePicker } = DatePicker;

const PeriodSelection = ({
	updateFunc = () => { },
	labelText = null
}) => {
	const [keyValue, setKeyValue] = useState(0);
	const { search } = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);
	const navigate = useNavigate();
	const datesFromUrl = useMemo(() => {
		const startDate = searchParams.get('startDate');
		const endDate = searchParams.get('endDate');
		if (startDate && endDate) {
			return [moment(startDate).format('DD/MM/YYYY'), moment(endDate).format('DD/MM/YYYY')].join(' --> ');
		}
		return null;

	}, [searchParams]);

	useEffect(() => {
		if (datesFromUrl == null) {
			setKeyValue(keyValue => keyValue + 1);
		}
	}, [datesFromUrl])
	const startQueryName = labelText ? `${labelText.toLowerCase()}StartDate` : 'startDate';
	const endQueryName = labelText ? `${labelText.toLowerCase()}EndDate` : 'endDate';

	const formatDateToLocalDateString = (date) => {
		const formatter = new Intl.DateTimeFormat('fr-FR', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			timeZone: 'Europe/Paris'
		});

		const [day, month, year] = formatter.format(date).split('/');
		return `${year}-${month}-${day}`;
	};

	const changeDates = (datesArr) => {
		let startDate = null;
		let endDate = null;
		if (datesArr && datesArr.length === 2 && datesArr[0] && datesArr[1]) {
			startDate = datesArr[0].startOf('day').toDate();
			endDate = datesArr[1].endOf('day').toDate();
		}
		if (startDate && endDate) {
			searchParams.set(startQueryName, formatDateToLocalDateString(startDate));
			searchParams.set(endQueryName, formatDateToLocalDateString(endDate));
		} else {
			searchParams.delete(startQueryName);
			searchParams.delete(endQueryName);
		}
		navigate(`?${searchParams.toString()}`, { replace: true });
		return updateFunc({
			startDate,
			endDate
		});
	};

	return (
		<div className={classNames(classes.container)}>
			{labelText && <p className={classNames(classes.label)}>{labelText}</p>}
			{datesFromUrl && <div style={{
				fontWeight: 'bold',
			}}>{datesFromUrl} <span className={classes.deleteIconButton} onClick={changeDates}>X</span></div>}
			<RangePicker
				key={keyValue}
				style={{ width: 'max-content' }}
				className={classNames(classes.blue)}
				onChange={changeDates}
			/>
		</div>
	);
};

export default PeriodSelection;
