import { useApi } from 'hooks/useApi';
import { useStoreUtils } from 'hooks/useStoreUtils';
import React, { useEffect, useState } from 'react';
import UsersTable from 'views/homeCompta/UserTable';

const AdminUsersPage = () => {
	const { getAllUsers } = useApi()
	const { state: { user } } = useStoreUtils()
	const [users, setUsers] = useState([])

	useEffect(() => {
		getAllUsers(user.id)
			.then(setUsers)
			.catch(e => console.log(e))
	}, [getAllUsers, user])

	return (
		<UsersTable users={users} title='Tous les utilisateurs' />
	);
};

export default AdminUsersPage;