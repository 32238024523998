import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { useApi } from 'hooks/useApi';
import { upperFirst } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'

const Transfer = () => {
	const { fetchApi } = useApi()
	const { userId } = useParams()
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		fetchApi('users')
			.then(setUsers)
	}, [userId, fetchApi])

	const user = useMemo(() => users.find(user => user.id === Number(userId)), [users, userId])
	const userName = useMemo(() => user ? upperFirst(user.firstname) + ' ' + upperFirst(user.lastname) : '', [user])
	const updateUserFunc = useCallback(async (infos) => {
		setLoading(true)
		try {
			await fetchApi(`action/transfer/${userId}/${infos.user}`, { method: 'POST' })
			setLoading(false)
			toast.success('Transfert effectué.')
			navigate('/admin/user/' + infos.user)
		} catch (error) {
			toast.error('Erreur durant le transfert : ' + error.message)
			setLoading(false)
		}
	}, [fetchApi, navigate, userId])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'user',
			label: 'Collaborateur',
			required: true,
			silentRequired: true,
			options: users.filter(e => e.id !== Number(userId)).map((user) => ({
				key: String(user.id),
				value: String(user.id),
				text: upperFirst(user.firstname) + ' ' + upperFirst(user.lastname)
			})),
		},

	], [users, userId])

	return (
		<div className={classes.marginAuto}>
			<h3>Choix de l'utilisateur vers lequel transférer tout le matériel de {userName}</h3>
			<FormComponent
				loading={loading}
				inputs={inputs}
				sendForm={updateUserFunc}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default Transfer;