import MaterialTable from '@material-table/core';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { omit } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { columnTypes } from './MyTable.constant';
import './MyTable2.scss'
import HeaderOldTable from './HeaderOldTable';
import BeforeDate from 'views/homeCompta/BeforeDate';

const MyOldTable = ({
	title = '',
	columns: columnsFromParams = [],
	data = [],
	options = {},
	filters = null,
	onRowClick = null,
	counter = false,
	beforeDate = false,
	total = null
}) => {
	const [rows, setRows] = useState(data)
	const { state: { tableLoading } } = useStoreUtils()
	useEffect(() => {
		setRows(data)
	}, [data])

	const prepareSortingObject = (obj, column) => {
		const { render, field, type = columnTypes.NUMERIC } = column
		let value = obj[field]
		if (render) {
			value = render(obj)
		}
		return {
			...obj,
			field,
			value,
			type,
			defaultValue: obj[field]
		}
	}

	const columnsFromParamsReady = useMemo(() => (columnsFromParams || [])
		.map(column => ({
			...omit(column, ['type']),
			customSort: (a, b) => {
				const first = prepareSortingObject(a, column)
				const second = prepareSortingObject(b, column)
				const { type } = first

				if (type === columnTypes.NUMERIC) {
					const firstValue = Number(first.value)
					const secondValue = Number(second.value)
					return firstValue - secondValue
				}

				if (type === columnTypes.TEXT) {
					const firstValue = first.value ? first.value.toString().toLowerCase() : ''
					const secondValue = second.value ? second.value.toString().toLowerCase() : ''
					return firstValue.localeCompare(secondValue)
				}

				if (type === columnTypes.DATE) {
					const firstDate = first.defaultValue ? new Date(first.defaultValue) : null
					const secondDate = second.defaultValue ? new Date(second.defaultValue) : null
					if (firstDate && secondDate) {
						return firstDate.getTime() - secondDate.getTime()
					}
					if (!firstDate && !secondDate) {
						return 0
					}
					return firstDate ? 1 : -1
				}

				return 0
			},
		})), [columnsFromParams])

	const [columns, setColumns] = useState(columnsFromParamsReady || [])

	useEffect(() => {
		setColumns(columnsFromParamsReady || [])
	}, [columnsFromParamsReady])

	return <>
		{beforeDate && <BeforeDate />}
		<div
			className='root'
		>
			<HeaderOldTable
				counter={counter}
				filters={filters}
				rows={data}
				setRows={setRows}
				setColumns={setColumns}
				columnsFromParams={columnsFromParamsReady}
				columns={columns} />
			<MaterialTable
				sorting={false}
				{...(onRowClick ? { onRowClick } : {})}
				title={title}
				columns={columns}
				data={rows}
				options={{
					headerStyle: { position: 'sticky', top: 0 },
					maxBodyHeight: '70vh',
					search: false,
					paging: false,
					...options,
					...(options.paging ? {
						pageSizeOptions: [100, 200, 1000, 10000],
					} : {})
				}}
				isLoading={tableLoading}
			/>
			{total &&
				<div style={{
					position: 'sticky', bottom: 0, zIndex: 1, background: 'white',
					color: 'violet',
					fontWeight: 'bold'
				}}>
					<table style={{ width: '100%', tableLayout: 'fixed' }}>
						<tfoot>
							<tr>
								{columns.map((column, ind) => (
									<td
										key={ind}
										style={{
											color: 'rgb(40 71 94)',
											fontWeight: 'bold',
											fontSize: 'small',
											width: `${100 / columns.length}%`,
											textAlign: 'left'
										}}
									>
										{ind === 0 ? 'Total' : ''}
										{total.includes(column.title) ? data.reduce((acc, row) => acc + (column.render ? column.render(row) : row[column.field]), 0) : ''}
									</td>
								))}
							</tr>
						</tfoot>
					</table>
				</div>
			}
		</div>
	</>
};

export default MyOldTable;
