import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import Button from 'components/Button';
import { getUserName } from 'helpers';
import { userTypes } from 'views/homeCompta/user.contant';
import MenuNavigationComponent from 'components/MenuNavigationComponent';
import GeneralTableView from 'views/homeCompta/GeneralTableView';
import { actionTypes } from 'views/homeCompta/action.constant';
import UserProductCountTable from 'views/homeCompta/UserProductCountTable';
import { useStoreUtils } from 'hooks/useStoreUtils';

const AdminUserPage = () => {
	const { isAdmin: amIAdmin } = useStoreUtils()
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const { getUser, updateUser, fetchApi } = useApi()
	const userId = Number(useParams().userId)
	const [user, setUser] = useState()
	const isAdmin = useMemo(() => user?.type === userTypes.ADMIN, [user])
	const components = useMemo(() => {
		const orderColumns = [
			'Date',
			"Type d'action",
			'Produit',
			'Quantité réelle',
			"Nom du client",
			"Adresse",
			'Entrepot',
			'Defectueux',
			'Statut'
		]
		return [
			{
				title: `Produits en stock`,
				component:
					<UserProductCountTable initialSearchParams={{ userORowner: userId }} userId={userId} />
			},
			{
				title: `Actions`,
				component:
					<GeneralTableView orderColumns={[...orderColumns, 'Utilisateur transféré', 'Utilisateur']} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ userORowner: userId }} />
			},
			{
				title: `Dépots en entrepôt`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ user: userId, type: actionTypes.DEPOT }} />
			},
			{
				title: `Installations`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ user: userId, type: actionTypes.INSTALLATION }} />
			},
			{
				title: `Tous les retraits`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ user: userId, type: actionTypes.RETRAIT }} />
			},
			{
				title: `Défectueux`,
				component:
					<GeneralTableView orderColumns={orderColumns} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ user: userId, type: actionTypes.INSTALLATION, defectueux: true }} />
			},
			{
				title: `Transferts`,
				component:
					<GeneralTableView orderColumns={[...orderColumns, 'Utilisateur transféré', 'Utilisateur']} navigable fetchType={"actions"} type={"action"} initialSearchParams={{ userORowner: userId, type: actionTypes.TRANSFER }} />
			},
		].filter(e => !!e)
	}, [userId])

	useEffect(() => {
		getUser(userId)
			.then(setUser)
	}, [userId, getUser])

	const updateUserFunc = useCallback(async (values) => {
		setLoading(true)
		try {
			await updateUser(userId, values)
			toast.success('Info(s) actualisé(s).')
			return setLoading(false)
		} catch (error) {
			setLoading(false)
			return toast.error('Erreur durant la modification de l\'utilisateur : ' + error.message)
		}
	}, [userId, updateUser])

	const inputs = useMemo(() => [
		{
			type: 'select',
			name: 'type',
			label: 'Type de collaborateur',
			required: true,
			silentRequired: true,
			value: user?.type ? String(user?.type) : null,
			options: Object
				.keys(userTypes)
				.map((collaboratorType, index) => ({
					key: String(userTypes[collaboratorType]),
					value: String(userTypes[collaboratorType]),
					text: collaboratorType
				})),
		},
		{
			type: 'text',
			name: 'firstname',
			label: 'Prénom',
			value: user?.firstname ? String(user?.firstname) : null,
		},
		{
			type: 'text',
			name: 'lastname',
			label: 'Nom',
			value: user?.lastname ? String(user?.lastname) : null,
		},
		{
			type: 'text',
			name: 'email',
			label: 'Email',
			value: user?.email ? String(user?.email) : null,
		},
	], [user])
	const inputsPwd = useMemo(() => [
		{
			type: 'password',
			name: 'password',
			label: 'Mot de passe',
		},
	], [])

	const blockUser = useCallback(async () => {
		try {
			await updateUser({ blocked: !!!user.blocked })
			setUser({
				...user,
				blocked: !!!user.blocked
			})
		} catch (error) {
			console.log('error', error)
		}

	}, [updateUser, user])

	const removeUser = useCallback(async () => {
		try {
			await fetchApi('user/' + userId, { method: 'DELETE' })
			toast.success('Utilisateur supprimé.')
			navigate('/admin/users')
		} catch (error) {
			console.log('error', error)
			toast.error('Erreur' + (error.message || 'Erreur durant la suppression de l\'utilisateur'))
		}

	}, [fetchApi, userId, navigate])



	const componentsForm = useMemo(() => [
		{
			title: `Dépots en entrepôt`,
			component: <div>
				<h3>Modification des informations</h3>
				<FormComponent
					loading={loading}
					inputs={inputs}
					sendForm={updateUserFunc}
					centered={true}
					responsive={false}
					segment={false}
				/>
			</div>
		},
		{
			title: `Modifier le mot de passe`,
			component: <div>
				<h3>Modification du mot de passe</h3>
				<FormComponent
					loading={loading}
					inputs={inputsPwd}
					sendForm={updateUserFunc}
					centered={true}
					responsive={false}
					segment={false}
				/>
			</div>
		}], [updateUserFunc, inputsPwd, loading, inputs])

	if (!user) {
		return null
	}

	return (
		<div className={classes.marginAuto}>
			<h2>Page de {getUserName(user)}{!!isAdmin ? ' - ADMIN' : ''}</h2>
			<p>{user?.email}</p>
			{amIAdmin && <><Button type='warning' onClick={blockUser}>{user.blocked ? 'Débloquer' : 'Bloquer'} l'utilisateur</Button>
				<Button type='danger' onClick={removeUser}>Supprimer l'utilisateur</Button>
				<Button type='danger' link={`/transfer/${userId}`}>Transférer le matériel de cet utilisateur vers un autre</Button>
				<div>
					<div>
						<MenuNavigationComponent
							queryName='infos'
							components={componentsForm}
						/>
					</div>
					<Button type='danger' link={`/action/create?type=${actionTypes.INSTALLATION}&defectueux=1&user=` + userId}>Déclarer un produit defectueux</Button>
					<Button type='secondary' link={`/action/create?type=${actionTypes.INSTALLATION}&user=` + userId}>Créer une installation</Button>
					<Button type='danger' link={`/action/create?type=${actionTypes.TRANSFER}&owner=` + userId}>Transférer un produit vers un autre utilisateur</Button>
				</div>
			</>}
			<MenuNavigationComponent
				queryName='view'
				components={components}
			/>
		</div>
	);
};

export default AdminUserPage;