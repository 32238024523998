import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classes from './BeforeDate.module.scss';
import classNames from 'classnames';
import { Input } from 'semantic-ui-react';

const BeforeDate = () => {

	const [searchParams] = useSearchParams()
	const value = useMemo(() => searchParams.get('beforeDate') || '', [searchParams])
	const navigate = useNavigate();

	const changeDate = (event) => {
		const date = event?.target?.value;
		if (date) {
			searchParams.set('beforeDate', date);
		} else {
			searchParams.delete('beforeDate');
		}
		navigate(`?${searchParams.toString()}`, { replace: true });
	};

	return (
		<div className={classNames(classes.container)}>
			<label className={classNames(classes.label)} htmlFor="beforeDate">Avant le</label>
			<Input
				id="beforeDate"
				type='date'
				onChange={changeDate}
				value={value}
			/>
			<span className={classes.deleteIconButton} onClick={changeDate}>X</span>
		</div>
	);
};

export default BeforeDate;
