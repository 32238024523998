export const actionTypes = {
	DEPOT: 0,
	RETRAIT: 1,
	INSTALLATION: 2,
	TRANSFER: 3
}

export const actionStatuses = {
	EN_ATTENTE: 0,
	VALIDEE: 1,
	REFUSEE: 2,
}