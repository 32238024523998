import { columnTypes } from 'components/MyTable/MyTable.constant';
import { useApi } from 'hooks/useApi';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import MyTable from 'components/MyTable/MyTable';
import classes from 'styles/classes.module.scss'
import { useStoreUtils } from 'hooks/useStoreUtils';

const UserProductCountTable = ({
	initialSearchParams = {},
	type,
	columns: columnsReceived = null,
	userId = false
}) => {
	const { state: { products: productsFromContext } } = useStoreUtils()
	const { fetchApi } = useApi()
	const [searchParamsFromHook] = useSearchParams()
	const searchParams = useMemo(() => {
		Object.entries(initialSearchParams)
			.map(([key, value]) => searchParamsFromHook.set(key, value))
		return searchParamsFromHook
	}, [searchParamsFromHook, initialSearchParams])
	const [products, setProducts] = useState([])

	const columns = useMemo(() => {
		return [
			{ field: 'name', title: 'Nom', type: columnTypes.TEXT },
			{ title: 'Quantité', field: 'totalQuantity', type: columnTypes.NUMERIC },
		].filter(e => !!e)
	}, [])

	const [nbTotalRow, setNbTotalRow] = useState()

	useEffect(() => {
		setNbTotalRow(productsFromContext?.length)
	}, [productsFromContext])

	useEffect(() => {
		const beforeDate = searchParams.get('beforeDate');
		let date = '';
		if (beforeDate) { date = `?beforeDate=${beforeDate}` }
		fetchApi(`products/user/${userId}${date}`)
			.then(([data, count]) => setProducts((productsFromContext || []).map(p => ({
				...p,
				totalQuantity: data.find(({ productName }) => productName === p.name)?.quantity || 0
			}))))
	}, [fetchApi, userId, searchParams, productsFromContext])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<MyTable
				beforeDate
				counter={true}
				columns={columns}
				nbTotalRow={nbTotalRow}
				data={products}
				title={'Produits en stock'}
				options={{
					paging: true,
					pageSize: 100
				}}
			/>
		</div>
	);
};

export default UserProductCountTable;