import SignIn from "./views/signIn";
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { isAuthenticated } from "./utils/Auth"
import "./App.scss";
import React, { useContext, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Provider, Store } from "store/store";
import classNames from "classnames";
import classes from './Home.module.scss'
import Header from "views/homeCompta/Header";
import AdminCreate from "views/admin/AdminCreate";
import AdminUserPage from "views/admin/AdminUserPage";
import MyUserPage from "views/admin/MyUserPage";
import AdminUsers from "views/admin/AdminUsers";
import EntrepotCreate from "views/homeCompta/EntrepotCreate";
import ProductCreate from "views/homeCompta/ProductCreate";
import { userTypes } from "views/homeCompta/user.contant";
import ActionCreate from "views/homeCompta/ActionCreate";
import Product from "views/homeCompta/Product";
import IndiceCreate from "views/homeCompta/IndiceCreate";
import GeneralTableView from "views/homeCompta/GeneralTableView";
import Entrepot from "views/homeCompta/Entrepot";
import Action from "views/homeCompta/Action";
import Actions from "views/homeCompta/Actions";
import Entrepots from "views/homeCompta/Entrepots";
import Relation from "views/homeCompta/Relation";
import RelationCreate from "views/homeCompta/RelationCreate";
import Transfer from "views/homeCompta/Transfer";
import { useApi } from "hooks/useApi";
import { useStoreUtils } from "hooks/useStoreUtils";
import { isEmpty } from "lodash";

const HomeRoute = () => {
	const { state: { user } } = useContext(Store)

	return !!!user ? <SignIn /> :
		<div className={classNames(classes.principalContainer)}>
			{/* <ApiTableNavigationProjects /> */}
		</div>

}

const RouteByType = ({ children, isAdminRoute }) => {
	const { state: { user } } = useContext(Store)
	if (!isAuthenticated()) {
		return <Navigate to="/signIn" />
	}

	if (isAdminRoute && user?.type !== userTypes.ADMIN) {
		return <Navigate to="/" />
	}

	return <div className={classNames(classes.principalContainer)}>
		{children}
	</div>
}

const InsideRouter = () => {
	const [isCharging, setIsCharging] = useState(false)
	const { state: { user, products } } = useStoreUtils()
	const { chargeProducts } = useApi();


	useEffect(() => {
		if (!isEmpty(user) && isEmpty(products) && !isCharging) {
			setIsCharging(true)
			chargeProducts()
		}
	}, [user, chargeProducts, products, isCharging])

	return <div className={classNames(classes.relative, classes.fullWidth, classes.taCenter)}>
		<Header />
		<Routes>
			<Route
				path='/signIn'
				element={<SignIn />}
			/>
			<Route
				element={
					<RouteByType isAdminRoute>
						<AdminCreate />
					</RouteByType>
				}
				path="/admin/create"
			/>
			<Route
				element={
					<RouteByType>
						<AdminUserPage />
					</RouteByType>
				}
				path="/admin/user/:userId"
			/>
			<Route
				element={
					<RouteByType >
						<AdminUsers />
					</RouteByType>
				}
				path="/admin/users"
			/>
			<Route
				element={
					<RouteByType>
						<MyUserPage />
					</RouteByType>
				}
				path="/settings"
			/>


			<Route
				element={<HomeRoute />}
				path="/"
			/>
			<Route
				element={
					<RouteByType >
						<EntrepotCreate />
					</RouteByType>
				}
				path="/entrepot/create"
			/>
			<Route
				element={
					<RouteByType >
						<ProductCreate />
					</RouteByType>
				}
				path="/product/create"
			/>
			<Route
				element={
					<RouteByType >
						<IndiceCreate />
					</RouteByType>
				}
				path="/indice/create"
			/>
			<Route
				element={
					<RouteByType >
						<Product />
					</RouteByType>
				}
				path="/product/:productId"
			/>

			<Route
				element={
					<RouteByType >
						<ActionCreate />
					</RouteByType>
				}
				path="/action/create"
			/>
			<Route
				element={
					<RouteByType >
						<RelationCreate />
					</RouteByType>
				}
				path="/relation/create"
			/>
			<Route
				element={
					<RouteByType >
						<Entrepots />
					</RouteByType>
				}
				path="/entrepots"
			/>
			<Route
				element={
					<RouteByType >
						<GeneralTableView type={"product"} navigable />
					</RouteByType>
				}
				path="/products"
			/>
			<Route
				element={
					<RouteByType >
						<GeneralTableView type={"relation"} navigable />
					</RouteByType>
				}
				path="/relations"
			/>
			<Route
				element={
					<RouteByType >
						<Actions />
					</RouteByType>
				}
				path="/actions"
			/>
			<Route
				element={
					<RouteByType >
						<Entrepot />
					</RouteByType>
				}
				path="/entrepot/:entrepotId"
			/>
			<Route
				element={
					<RouteByType >
						<Relation />
					</RouteByType>
				}
				path="/relation/:relationId"
			/>
			<Route
				element={
					<RouteByType >
						<Action />
					</RouteByType>
				}
				path="/action/:actionId"
			/>
			<Route
				element={
					<RouteByType >
						<Transfer />
					</RouteByType>
				}
				path="/transfer/:userId"
			/>
		</Routes>
	</div>
}

const Router = () => {



	return <BrowserRouter>
		<InsideRouter />
	</BrowserRouter>
}

const App = () => {
	return (
		<Provider>
			<ToastContainer position="top-right" />
			<Router />
		</Provider>
	);
}

export default App;