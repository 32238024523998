import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import { getOnlyUpdatedValues } from 'helpers';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { useApi } from 'hooks/useApi';
import IndicesTable from './IndiceTable';
import { actionTypes } from './action.constant';

const Product = () => {
	const { fetchApi } = useApi()
	const navigate = useNavigate()
	const id = Number(useParams().productId)
	const [product, setProduct] = useState({})
	const [loading, setLoading] = useState(false)
	const [indices, setIndices] = useState([])
	const removeIndice = useCallback(async (id) => {
		try {
			await fetchApi('indice?id=' + id, { method: 'DELETE' })
			setIndices(indices => indices.filter(indice => indice.id !== id))
		} catch (error) {
			toast.error('Erreur durant la suppression de l\'indice: ' + error.message)
		}
	}, [fetchApi, setIndices])

	useEffect(() => {
		fetchApi('product?id=' + id)
			.then(setProduct)
		fetchApi('indices?product=' + id)
			.then(setIndices)
	}, [fetchApi, id])

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'name',
			label: 'Nom',
			value: product.name ? String(product.name) : null,
			required: true,
			silentRequired: true,
		},
		{
			type: 'description',
			name: 'description',
			label: 'Description',
			value: product.description ? String(product.description) : null,
		},
		{
			type: 'number',
			name: 'price',
			label: 'Prix (HT)',
			value: product.price ? String(product.price) : null,
			required: true,
			silentRequired: true,
		},
		{
			type: 'number',
			name: 'tva',
			label: 'TVA',
			value: product.tva ? String(product.tva) : null,
			required: true,
			silentRequired: true,
		},
	], [product])

	const update = useCallback(async (values) => {
		setLoading(true)
		try {
			const updatedValues = getOnlyUpdatedValues({
				...product,
			}, values, true)
			if (isEmpty(updatedValues)) {
				toast.error('Aucune info n\'a changé.')
				return
			}
			await fetchApi('product?id=' + id, {
				method: 'PUT',
				body: { ...updatedValues },
			})
			setProduct(product => ({ ...product, ...updatedValues }))
			toast.success('Infos actualisées')
		} catch (error) {
			toast.error('Erreur durant l\'actualisation des infos :' + error.message)
			console.log('error', error)
			return
		} finally {
			setLoading(false)
		}
	}, [fetchApi, id, product])

	return (
		<>
			<div className={classNames(
				classes.marginAuto,
				// classes.flex,
				// classes.flexWrap
			)}>
				<Button link={'/indice/create?product=' + id}>Créer un indice</Button>
				<Button link={`/action/create?type=${actionTypes.DEPOT}&product=` + id}>Créer un dépôt</Button>
				<Button link={`/action/create?type=${actionTypes.RETRAIT}product=` + id}>Créer un retrait</Button>
				<Button type='secondary' link={`/installation/create?product=` + id}>Créer une installation</Button>
				<div className={classNames(
					classes.marginAuto,
					classes.flex,
					classes.flexWrap
				)}>
					<div>
						<h3>Informations du produit</h3>
						<FormComponent
							loading={loading}
							inputs={inputs}
							sendForm={update}
							centered={true}
							responsive={false}
							segment={false}
							messages={{ submit: 'Actualiser les infos' }}
						/>
					</div>
				</div>
			</div>
			<IndicesTable indices={indices} removeIndice={removeIndice} />
			{/* {!!similarTo && <div>
				<h1 className={classes.taLeft}>{product.type === productTypes.PROJECT ? 'Projet' : 'Audit'} ressemblant à ces {product.type === productTypes.PROJECT ? 'projet' : 'audit'}s</h1>
				<ProductsTable isAudit={product?.type === productTypes.AUDIT} products={similarTo} actionDisable />
			</div>} */}
		</>
	);
};

export default Product;