import React, { useCallback } from 'react';
import FiltersTable from './FiltersTable';
import classes from '../../styles/classes.module.scss'
import SearchTable from './SearchTable';
import { useTableHeader } from './useTableHeader';
import classNames from 'classnames';
// import FilterColumns from './FilterColumns';
import Counter from './Counter';
import Button from 'components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';

const HeaderTable = ({
	rows = [],
	setRows = () => { },
	filters: filtersFromParams = null,
	setColumns = () => { },
	columnsFromParams = [],
	columns = [],
	counter = false
}) => {
	const { updateFilters } = useTableHeader(rows, setRows)
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const resetFilters = useCallback(() => {
		searchParams.delete('sortBy')
		searchParams.delete('sortDirection')
		searchParams.delete('search')
		navigate(`?${searchParams.toString()}`, { replace: true })
	}, [searchParams, navigate])
	return (
		<div className={classNames(classes.flex, classes.flexWrap)}>
			<SearchTable
				updateFilters={value => updateFilters('search', value)}
			/>
			<Button type='secondary' onClick={resetFilters}>Réinitialiser les filtres</Button>

			{/* <FilterColumns
                setColumns={setColumns}
                columnsFromParams={columnsFromParams}
                columns={columns}
            /> */}

			{filtersFromParams && <FiltersTable
				filters={filtersFromParams}
				setRows={setRows}
				updateFilters={value => updateFilters('filter', value)}

			/>}
			{counter && <Counter />}
		</div>
	);
};

export default HeaderTable;