import classes from 'styles/classes.module.scss'
import MyTable from 'components/MyTable/MyTable';
import React, { useMemo } from 'react';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import Button from 'components/Button';

const IndicesTable = ({ indices: indicesReceived, title = '', removeIndice = () => {} }) => {
	const indices = useMemo(() => indicesReceived, [indicesReceived])


	const columns = useMemo(() => [
		{ field: 'name', title: 'Nom', type: columnTypes.TEXT },
		{ field: 'quantity', title: 'Quantité', type: columnTypes.NUMERIC },
		{ field: 'quantity', title: 'Quantité', type: columnTypes.NUMERIC },
		{
			title: 'Actions', type: columnTypes.TEXT, render: ({ id }) => <>
				<Button type='danger' onClick={() => removeIndice(id)}>Supprimer</Button>
			</>
		},
	], [removeIndice])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
				<MyTable
					counter={true}
					columns={columns}
					data={indices}
					title={title}
					options={{
						paging: true,
						pageSize: 100
					}}
				/>
			</div>
		</div>
	);
};

export default IndicesTable;