import { useStoreUtils } from 'hooks/useStoreUtils';
import { omit } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import HeaderTable from './HeaderTable';
import { columnTypes } from './MyTable.constant';
import './MyTable2.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, TableFooter } from '@mui/material'
import BeforeDate from 'views/homeCompta/BeforeDate';

const MyTable = ({
	title = '',
	columns: columnsFromParams = [],
	data = [],
	nbTotalRow = null,
	options = {},
	filters = null,
	onRowClick = null,
	counter = false,
	total = null,
	beforeDate = false
}) => {
	const location = useLocation()
	const navigate = useNavigate()
	const count = nbTotalRow ? nbTotalRow : data.length
	const searchParams = new URLSearchParams(location.search)
	const pageQuery = searchParams.get('page')
	const limitQuery = searchParams.get('limit')
	const [page, setPage] = useState(pageQuery ? pageQuery - 1 : 0)
	const [limit, setLimit] = useState(limitQuery ? limitQuery : options.pageSizeOptions ? options.pageSizeOptions[0] : 100)
	const [rows, setRows] = useState(data)
	const [sortBy, setSortBy] = useState(null)
	const [sortDirection, setSortDirection] = useState('DESC')
	const { state: { tableLoading } } = useStoreUtils()

	useEffect(() => {
		if (sortBy) {
			searchParams.set('sortBy', sortBy)
		} else {
			searchParams.delete('sortBy')
		}
		if (sortDirection) {
			searchParams.set('sortDirection', sortDirection)
		} else {
			searchParams.delete('sortDirection')
		}
		navigate(`?${searchParams.toString()}`, { replace: true })
	}, [sortBy, sortDirection])

	useEffect(() => {
		setRows(data)
	}, [data])

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const newPageQuery = searchParams.get('page');

		if (newPageQuery !== null) {
			const newPage = parseInt(newPageQuery, 10) - 1; // Converti en nombre et ajuste pour l'indexation à base zéro
			setPage(newPage);
		}
	}, [location.search])

	useEffect(() => {
		searchParams.set('page', page + 1)
		searchParams.set('limit', limit)
		navigate(`?${searchParams.toString()}`, { replace: true })
	}, [page, limit])

	const prepareSortingObject = (obj, column) => {
		const { render, field, type = columnTypes.NUMERIC } = column
		let value = obj[field]
		if (render) {
			value = render(obj)
		}
		return {
			...obj,
			field,
			value,
			type,
			defaultValue: obj[field]
		}
	}

	const columnsFromParamsReady = useMemo(() => (columnsFromParams || [])
		.map(column => ({
			...omit(column, ['type']),
			customSort: (a, b) => {
				const first = prepareSortingObject(a, column)
				const second = prepareSortingObject(b, column)
				const { type } = first

				if (type === columnTypes.NUMERIC) {
					const firstValue = Number(first.value)
					const secondValue = Number(second.value)
					return firstValue - secondValue
				}

				if (type === columnTypes.TEXT) {
					const firstValue = first.value ? first.value.toString().toLowerCase() : ''
					const secondValue = second.value ? second.value.toString().toLowerCase() : ''
					return firstValue.localeCompare(secondValue)
				}

				if (type === columnTypes.DATE) {
					const firstDate = first.defaultValue ? new Date(first.defaultValue) : null
					const secondDate = second.defaultValue ? new Date(second.defaultValue) : null
					if (firstDate && secondDate) {
						return firstDate.getTime() - secondDate.getTime()
					}
					if (!firstDate && !secondDate) {
						return 0
					}
					return firstDate ? 1 : -1
				}

				return 0
			},
		})), [columnsFromParams])

	const [columns, setColumns] = useState(columnsFromParamsReady || [])

	useEffect(() => {
		setColumns(columnsFromParamsReady || [])
	}, [columnsFromParamsReady])

	const handleChangePage = useCallback((event, newPage) => {
		setPage(newPage)
	}, [])

	const handleChangeRowsPerPage = useCallback((event) => {
		setLimit(event.target.value)
	}, [])

	return <>
		{beforeDate && <BeforeDate />}
		<div
			className='root'
		>
			<HeaderTable
				counter={counter}
				filters={filters}
				rows={rows}
				setRows={setRows}
				setColumns={setColumns}
				columnsFromParams={columnsFromParamsReady}
				columns={columns} />
			<Paper elevation={0} variant={"outlined"} square>
				<TableContainer component={Paper} style={{ maxHeight: options.maxBodyHeight || '65vh' }}>
					<Table stickyHeader aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								{columns.map((column, ind) => (
									<TableCell
										key={ind}
										style={{ top: 0, cursor: 'pointer' }}
										onClick={() => {
											setSortBy(column.field)
											setSortDirection(sortDirection === 'DESC' ? 'ASC' : 'DESC')
										}}
									>
										{column.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row, index) => (
								<TableRow hover onClick={(event) => onRowClick && onRowClick(event, row, index)} key={row.id}>
									{columns.map((column, ind) => (
										<TableCell key={ind}>
											{column.render ? column.render(row) : row[column.field]}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
						{total &&
							<TableFooter style={{
								position: 'sticky', bottom: 0, zIndex: 1, background: 'white',
								color: 'violet',
								fontWeight: 'bold'
							}}>
								<TableRow>
									{columns.map((column, ind) => (
										<TableCell
											key={ind}
											style={{ top: 0, color: 'rgb(40 71 94)', fontWeight: 'bold', fontSize: 'small' }}
										>
											{ind === 0 ? 'Total' : ''}
											{total.includes(column.title) ? data.reduce((acc, row) => acc + (column.render ? column.render(row) : row[column.field]), 0) : ''}
										</TableCell>
									))}
								</TableRow>
							</TableFooter>
						}
					</Table>
				</TableContainer>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
					<TablePagination
						rowsPerPageOptions={options.pageSizeOptions || [100, 200, 1000]}
						component="div"
						count={Number(count)}
						rowsPerPage={Number(limit)}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count !== -1 ? count : `plus que ${to}`}`}
					/>
				</div>
			</Paper>
		</div>
	</>
};

export default MyTable;