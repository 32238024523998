import { useCallback, useContext } from "react"
import { useApi } from "./useApi"
import toast from "components/toast"
import { saveToken } from "utils/Auth"
import { Store } from "store/store"
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from "react-router-dom"
import { useDisconnect } from "./useDisconnect"

export const useAuth = () => {
	const { logout } = useDisconnect()
	const { dispatch, state: { user } } = useContext(Store)
	const { fetchApi } = useApi()
	const navigate = useNavigate()
	const login = useCallback(async ({ email, password }) => {
		try {
			const token = await fetchApi(`getMe`, {
				method: 'POST',
				body: {
					email: email,
					password: password,
				},
			})

			saveToken(token)
			const user = jwtDecode(token)
			dispatch({
				type: 'USER/UPDATE',
				payload: user
			})
			toast.success('Connecté')
			navigate('/')

		} catch (error) {
			if (error.message === 'blocked') {
				return toast.error("Votre compte est bloqué!")
			}
			return toast.error("Nom d'utilisateur ou mot de passe incorrect!")
		}
	}, [dispatch, fetchApi, navigate])

	return {
		login,
		logout,
		isAuthenticated: !!user
	}

}