import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Input } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchTable = ({ updateFilters = () => { } }) => {
	const { search } = useLocation();
	const searchParams = useMemo(() => new URLSearchParams(search), [search]);
	const navigate = useNavigate();
	const value = useMemo(() => searchParams.get('search') || '', [searchParams]);
	const [key, setKey] = useState(0);
	const inputRef = useRef(null); // Create a ref for the input element

	useEffect(() => {
		setKey(key + 1);
		if (inputRef.current) {
			inputRef.current.focus(); // Focus the input element
		}
	}, [value]);

	const handleSearchChange = useCallback((evt) => {
		const { value } = evt.target;
		if (value) {
			searchParams.set('search', value);
		} else {
			searchParams.delete('search');
		}
		searchParams.set('page', 1);
		navigate(`?${searchParams.toString()}`, { replace: true });
		updateFilters(value);
	}, [updateFilters, searchParams, navigate]);

	const handleChange = useMemo(
		() => debounce(handleSearchChange, 500),
		[updateFilters, handleSearchChange]
	);

	return (
		<Input
			ref={inputRef} // Attach the ref to the input element
			key={key}
			style={{
				height: 'fit-content',
				marginTop: 'auto'
			}}
			icon='search'
			placeholder={'Rechercher'}
			onChange={handleChange}
			defaultValue={value}
			autoFocus
		/>
	);
};

export default SearchTable;
