import FormComponent from 'components/Form/Form';
import toast from 'components/toast';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import { Store } from 'store/store';

const ProductCreate = () => {
	const { state: { user } } = useContext(Store)
	const { fetchApi } = useApi()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const create = useCallback(async (infos) => {
		setLoading(true)
		try {
			const product = await fetchApi('product', { body: { ...infos, owner: user.id } })
			toast.success('produit créé.')
			setLoading(false)
			navigate('/product/' + product.id)
		} catch (error) {
			setLoading(false)
			toast.error('Erreur durant la création de l\'produit: ' + error.message)
		}
	}, [user, fetchApi, navigate])

	const inputs = useMemo(() => [
		{
			type: 'text',
			name: 'name',
			label: 'Nom',
			required: true,
			silentRequired: true,
		},
		{
			type: 'description',
			name: 'description',
			label: 'Description',
		},
		{
			type: 'number',
			name: 'price',
			label: 'Prix (HT)',
			required: true,
			silentRequired: true,
		},
		{
			type: 'number',
			name: 'tva',
			label: 'TVA',
			value: '0.2',
			required: true,
			silentRequired: true,
		},
	].filter(e => !!e), [])


	return (
		<div className={classes.marginAuto}>
			<h2>Création du produit</h2>
			<FormComponent
				loading={loading}
				inputs={inputs}
				sendForm={create}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default ProductCreate;