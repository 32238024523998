import MyOldTable from 'components/MyTable/MyOldTable';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { useApi } from 'hooks/useApi';
import { useStoreUtils } from 'hooks/useStoreUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom/dist';
import classes from 'styles/classes.module.scss'

const ProductsParentTotalTable = () => {
	const { state: { products: productsFromContext } } = useStoreUtils()
	const [searchParams] = useSearchParams()
	const entrepotId = Number(useParams().entrepotId)

	const { fetchApi } = useApi()
	const [products, setProducts] = useState([])
	useEffect(() => {
		const beforeDate = searchParams.get('beforeDate');
		let date = '';
		if (beforeDate) { date = `?beforeDate=${beforeDate}` }
		fetchApi(`products/entrepot/${entrepotId}${date}`)
			.then(([data, count]) => setProducts((productsFromContext || []).map(p => ({
				...p,
				totalQuantity: data.find(({ id }) => id === p.id)?.totalQuantity || 0
			}))))
	}, [fetchApi, entrepotId, searchParams, productsFromContext])

	const columns = useMemo(() => {
		return [
			{ field: 'name', title: 'Nom', type: columnTypes.TEXT },
			{ title: 'Quantité', field: 'totalQuantity', type: columnTypes.NUMERIC },
		].filter(e => !!e)
	}, [])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<MyOldTable
				counter={true}
				columns={columns}
				data={products}
				options={{
					paging: true,
					pageSize: 100
				}}
				beforeDate={true}
			// onRowClick={navigable ? onRowClick : () => { }}
			/>
		</div>
	);
};

export default ProductsParentTotalTable;