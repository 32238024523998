import classes from 'styles/classes.module.scss'
import MyTable from 'components/MyTable/MyTable';
import React, { useEffect, useMemo, useState } from 'react';
import Button from 'components/Button';
import moment from 'moment';
import { columnTypes } from 'components/MyTable/MyTable.constant';
import { getUserName } from 'helpers';
import MyOldTable from 'components/MyTable/MyOldTable';
import { useNavigate } from 'react-router-dom';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { useSearchParams } from 'react-router-dom/dist';
import { useApi } from 'hooks/useApi';
import { isEmpty } from 'lodash';

const UsersTable = ({ users: usersReceived, title = '' }) => {
	const { state: { products: productsFromContext } } = useStoreUtils()
	const [searchParams] = useSearchParams()

	const { fetchApi } = useApi()
	const navigate = useNavigate()
	const [users, setUsers] = useState(usersReceived.map(user => ({
		...user,
		displayName: getUserName({
			firstname: user.firstname,
			lastname: user.lastname,
			email: user.email
		})
	})))
	useEffect(() => {
		if (!isEmpty(usersReceived)) {
			const beforeDate = searchParams.get('beforeDate');
			let date = '';
			if (beforeDate) { date = `?beforeDate=${beforeDate}` }
			Promise.all(usersReceived.map(user =>
				fetchApi(`products/user/${user.id}${date}`)
					.then(([data, count]) => ({
						...user,
						displayName: getUserName({
							firstname: user.firstname,
							lastname: user.lastname,
							email: user.email
						}),
						...(productsFromContext || [])
							.reduce((acc, { name }) => ({
								...acc,
								[`Quantité de ${name}`]: data.find(product => product.productName === name)?.quantity || 0
							}), {})
					}))
			)).then(setUsers)
		}

	}, [usersReceived, searchParams, productsFromContext, fetchApi])

	const columns = useMemo(() => [
		{ field: 'id', title: 'ID', render: ({ id }) => /*<Button link={`/synthese/${id}`}>{*/id/*}</Button>*/ },
		{ field: 'displayName', title: 'Utilisateur', type: columnTypes.TEXT },
		...productsFromContext.map(({ name }) => ({ field: `Quantité de ${name}`, title: `Quantité de ${name}`, type: columnTypes.NUMERIC })),
		{ field: 'createdAt', title: 'Date de création', type: columnTypes.DATE, render: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY') },
	], [productsFromContext])

	return (
		<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
			<div style={{ maxWidth: "100%" }} className={classes.marginAuto}>
				<MyOldTable
					beforeDate
					onRowClick={(_, { id }) => navigate(`/admin/user/${id}`)}
					columns={columns}
					data={users}
					title={title}
					total={productsFromContext.map(({ name }) => `Quantité de ${name}`)}
				/>
			</div>
		</div>
	);
};

export default UsersTable;