import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import FormComponent from 'components/Form/Form';
import classes from 'styles/classes.module.scss'
import { useApi } from 'hooks/useApi';
import toast from 'components/toast';
import { getOnlyUpdatedValues } from 'helpers';
import { isEmpty } from 'lodash';
import Button from 'components/Button';

const Relation = () => {
	const [searchParams] = useSearchParams()
	const { relationId } = useParams()
	const { fetchApi } = useApi()
	const [products, setProducts] = useState([])
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [relation, setRelation] = useState([])
	const toProduct = useMemo(() => Number(searchParams.get('toProduct') || 0) || relation.toProduct || null, [searchParams, relation])
	const fromProduct = useMemo(() => Number(searchParams.get('fromProduct') || 0) || relation.fromProduct || null, [searchParams, relation])

	const [indices, setIndices] = useState([])

	useEffect(() => {
		fetchApi('products')
			.then(setProducts)
		fetchApi('relation?id=' + relationId)
			.then(setRelation)
	}, [fetchApi, relationId])

	useEffect(() => {
		const actualToProduct = searchParams.get('toProduct')
		const actualFromProduct = searchParams.get('fromProduct')
		if (!isEmpty(relation) && !actualToProduct && !actualFromProduct) {
			searchParams.set('toProduct', relation.toProduct)
			searchParams.set('fromProduct', relation.fromProduct)
			navigate(`/relation/${relationId}?` + searchParams.toString(), { replace: true })
		}
	}, [relation, searchParams, navigate, relationId])


	useEffect(() => {
		if (toProduct != null) {
			fetchApi('indices?product=' + toProduct)
				.then(setIndices)
		}
	}, [toProduct, fetchApi])

	const onUpdate = useCallback((updatableValues) => {
		const initialSearch = searchParams.toString()
		const actualToProduct = searchParams.get('toProduct')
		if (actualToProduct !== updatableValues.toProduct) {
			if (!updatableValues.toProduct) {
				searchParams.delete('toProduct')
			} else {
				searchParams.set('toProduct', updatableValues.toProduct)
			}
		}
		const actualFromProduct = searchParams.get('fromProduct')
		if (actualFromProduct !== updatableValues.fromProduct) {
			if (!updatableValues.fromProduct) {
				searchParams.delete('fromProduct')
			} else {
				searchParams.set('fromProduct', updatableValues.fromProduct)
			}
		}
		if (searchParams.toString() !== initialSearch) {
			navigate(`/relation/${relationId}?` + searchParams.toString(), { replace: true })
		}
	}, [searchParams, navigate, relationId])

	const update = useCallback(async (infos) => {
		const updatedValues = getOnlyUpdatedValues({
			...relation,
		}, infos, true)
		if (isEmpty(updatedValues)) {
			toast.error('Aucune info n\'a changé.')
			return
		}
		setLoading(true)
		try {
			await fetchApi('relation', {
				method: 'PUT',
				body: updatedValues
			})
			toast.success('Relation modifiée.')
			setLoading(false)
			searchParams.set('toProduct', infos.toProduct)
			searchParams.set('fromProduct', infos.fromProduct)
			navigate(`/relation/${relationId}?` + searchParams.toString(), { replace: true })
			return
		} catch (error) {
			setLoading(false)
			toast.error('Erreur durant la modification de la relation: ' + error.message)
		}
	}, [fetchApi, navigate, relationId, searchParams, relation])

	const removeRelation = useCallback(async (infos) => {
		try {
			await fetchApi('relation', {
				method: 'DELETE',
			})
			toast.success('Relation supprimée.')
			setLoading(false)
			navigate(`/relations`, { replace: true })
			return
		} catch (error) {
			toast.error('Erreur durant la modification de la relation: ' + error.message)
		}
	}, [fetchApi, navigate])

	const inputs = useMemo(() => [
		{
			type: 'autocomplete',
			name: 'fromProduct',
			label: 'Produit déclencheur',
			required: true,
			silentRequired: true,
			value: fromProduct
				? String(fromProduct)
				: relation.fromProduct
					? String(relation.fromProduct)
					: null,
			options: products.map((product) => ({
				key: String(product.id),
				value: String(product.id),
				text: product.name
			})),
		},
		{
			type: 'autocomplete',
			name: 'toProduct',
			label: 'Produit automatiquement ajouté',
			info: "Une fois le produit déclencheur ajouté, une nouvelle fenêtre s'ouvrira pour ajouter ce produit et sera pré-remplie.",
			required: true,
			silentRequired: true,
			value: toProduct
				? String(toProduct)
				: relation.toProduct
					? String(relation.toProduct)
					: null,
			options: products.map((product) => ({
				key: String(product.id),
				value: String(product.id),
				text: product.name
			})),
		},
		{
			type: 'autocomplete',
			name: 'indice',
			label: 'Indice',
			value: relation.indice ? String(relation.indice) : null,
			options: indices.map((indice) => ({
				key: String(indice.id),
				value: String(indice.id),
				text: indice.name + ' x' + indice.quantity
			})),
		},
		{
			type: 'number',
			name: 'quantity',
			value: relation.quantity,
			label: 'Quantité',
		},
	].filter(e => !!e), [fromProduct, relation, products, toProduct, indices])


	return (
		<div className={classes.marginAuto}>
			<Button type='danger' onClick={removeRelation}>Supprimer la relation</Button>

			<h2>Modifier la relation</h2>
			<FormComponent
				onUpdate={onUpdate}
				key={JSON.stringify(inputs)}  // Utilisation de JSON.stringify pour générer une clé unique basée sur les inputs
				loading={loading}
				inputs={inputs}
				sendForm={update}
				centered={true}
				responsive={false}
				segment={false}
			/>
		</div>
	);
};

export default Relation;
